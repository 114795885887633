import moment from "moment";
/* API Url */
export const API_DOMAIN = "https://api.secretstory.network";
// export const API_DOMAIN = "http://localhost:3002";
// export const API_DOMAIN = "http://localhost";

export const SOCKET_URL = `${API_DOMAIN}`;
export const API_VERSION = "/api/v1";

/* End points */
export const GET_ALL_STORIES_API = `${API_DOMAIN}${API_VERSION}/story`;
export const AUTH_LOGIN_API = `${API_DOMAIN}${API_VERSION}/user/login`;
export const ALL_USERS = `${API_DOMAIN}${API_VERSION}/user/all`;
export const AUTH_REGISTER = `${API_DOMAIN}${API_VERSION}/user/register`;
export const CREATE_NEW_STORY_API = `${API_DOMAIN}${API_VERSION}/story`;
export const GET_ALL_MEDIA_MESSAGES = `${API_DOMAIN}${API_VERSION}/message/all/media/messages`;
export const GET_ALL_MESSAGES = `${API_DOMAIN}${API_VERSION}/message/all`;
export const SEND_NEW_MESSAGE = `${API_DOMAIN}${API_VERSION}/message`;
export const GET_ALL_CHARACTERS = `${API_DOMAIN}${API_VERSION}/character/all`;
export const ADD_NEW_CHARACTER = `${API_DOMAIN}${API_VERSION}/character`;
export const UPDATE_CHARACTER = `${API_DOMAIN}${API_VERSION}/character`;
export const DELETE_CHARACTER = `${API_DOMAIN}${API_VERSION}/character`;
export const GET_ALL_PLAYERS = `${API_DOMAIN}${API_VERSION}/player/all`;
export const PLAYERS = `${API_DOMAIN}${API_VERSION}/player`;
export const GET_INVITATION = `${PLAYERS}/invite`;
export const ACCEPT_INVITATION_BY_CODE = `${PLAYERS}/invite/acceptbycode`;
export const STICKY_POST = `${API_DOMAIN}${API_VERSION}/story/sticky-post`;
export const CLOSE_STICKY_POST = `${API_DOMAIN}${API_VERSION}/story/closesticky-post`;
export const SEND_DICE_ROLL = `${API_DOMAIN}${API_VERSION}/message/diceroll`;
export const DELETE_CONDUCTOR = `${API_DOMAIN}${API_VERSION}/user`;
/* AWS S3 bucket credentials */
export const S3_BUCKET = "bellyfeelsecretstorynetwork";
export const REGION = "eu-west-2";
export const ACCESS_KEY = "AKIA2ACKJUQNI5KAWF5W";
export const SECRET_ACCESS_KEY = "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";



/*Story start audio link  */
export const ANIMATION_AUDIO =
  "https://bellyfeelsecretstorynetwork.s3.eu-west-2.amazonaws.com/SSN+curtians.mp3";

//old audio - "https://bellyfeelsecretstorynetwork.s3.eu-west-2.amazonaws.com/SSN+intro.mp3";

// Common module :
/* Function to check string is LINK or not */
export const isValidURL = (string) => {
  var res = string.match(
    "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
  );
  return res === null ? false : true;
};

/* Function to check Audio Url */
export function checkAudioURL(url) {
  return (
    url.match(
      /\.(wav|mpeg|aac|aacp|ogg|webm|x-caf|flac|mp3|m4a|amr|opus|)$/
    ) != null
  );
}

/* Function to check image Url */
export function isImageUrl(url) {
  const extensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp', 'ico'];
  const pattern = new RegExp(`\\.(${extensions.join('|')})$`, 'i');
  console.log(pattern.test(url))
  return pattern.test(url);
}


/* Function to validate Phone Number */
export function validatePhoneNumber(input_str) {
  var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return re.test(input_str);
}

/* Function to Format date */
export const dateConversion = (date) => {
  try {
    const dateToFormat = new Date(date);
    if (
      moment(moment(dateToFormat).format("YYYY-MM-DD")).isSame(
        moment().format("YYYY-MM-DD")
      )
    )
      return `Today ${moment(dateToFormat).format("hh:mm A")}`;
    else return moment(dateToFormat).format("YYYY-MM-DD hh:mm A");
  } catch (error) {
    console.log(error);
  }
};

/* Function to check Video Url */
export function checkVideoURL(url) {
  return (
    url.match(
      /\.(mp4|avi|mkv|mov|wmv|flv|webm|m4v|mpeg|mpg|ogv|)$/
    ) != null
  );
}